import { Card, CardHeader, Typography } from "@material-ui/core";
import {
  ChipField,
  DateField,
  Edit,
  FunctionField,
  Labeled,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import { DefaultEditToolbar, PublishedInput } from "../../common";
import {
  ParticipationMediaField,
  ParticipationNameField,
} from "../../participations";
import { UserAvatar, UserNameField } from "../../users";

import PropTypes from "prop-types";
import React from "react";
import { ResourceCommentList } from "../../comments";
import departements from "../../data/departements";

const getDiagnostic = (record) => {
  for (let obs of record.observations) {
    if (obs.model === "diagnostic") {
      return obs;
    }
  }

  return null;
};

const InteretPhenomeneField = ({ record, ...rest }) => {
  return record.data ? (
    <Labeled {...rest}>
      <div>
        {record.data.interetPhenomene.map((interetPhenomene) => (
          <div key={interetPhenomene.interet}>
            <ReferenceField
              record={interetPhenomene}
              basePath={
                "observatories/" +
                process.env.REACT_APP_VIGIETERRE_OBS_ID +
                "/participations"
              }
              link="false"
              source="interet"
              reference="ThesaurusValues"
              style={{
                display: "block",
                marginBottom: "5px",
              }}
            >
              <TextField source="title" />
            </ReferenceField>
            <ReferenceArrayField
              record={interetPhenomene}
              basePath={
                "observatories/" +
                process.env.REACT_APP_VIGIETERRE_OBS_ID +
                "/participations"
              }
              link={false}
              reference="ThesaurusValues"
              source="pheno"
            >
              <SingleFieldList link={false}>
                <ChipField source="title" />
              </SingleFieldList>
            </ReferenceArrayField>
          </div>
        ))}
      </div>
    </Labeled>
  ) : (
    ""
  );
};

InteretPhenomeneField.propTypes = {
  record: PropTypes.object,
};

const Descriptions = (props) => {
  const descriptions = props.record.observations.filter(
    (obs) => obs.model === "description"
  );

  if (descriptions.length === 0) {
    return (
      <SimpleShowLayout>
        <Typography variant="body2">Aucune description</Typography>
      </SimpleShowLayout>
    );
  }

  return descriptions.map((description) => (
    <DescriptionCard
      key={description.id}
      observatoryId={props.record.observatoryId}
      record={description}
    />
  ));
};

Descriptions.propTypes = {
  record: PropTypes.object,
};

export const DescriptionCard = ({ observatoryId, record, ...rest }) => {
  const username = (
    <ReferenceField
      source="userId"
      basePath={"observatories/" + observatoryId + "/users"}
      reference={"observatories/" + observatoryId + "/users"}
      record={record}
      link="edit"
      {...rest}
    >
      <UserNameField withAvatar={false} />
    </ReferenceField>
  );

  const avatar = (
    <ReferenceField
      source="userId"
      basePath={"observatories/" + observatoryId + "/users"}
      reference={"observatories/" + observatoryId + "/users"}
      record={record}
      link="edit"
      {...rest}
    >
      <UserAvatar />
    </ReferenceField>
  );

  const cardStyle = {
    margin: "1em 0",
    display: "block",
  };

  return (
    <Card style={cardStyle}>
      <CardHeader
        title={username}
        avatar={avatar}
        subheader={<DateField record={record} source="created" showTime />}
      />
      <SimpleShowLayout record={record}>
        <FunctionField
          label="Site non observable"
          render={(record) => {
            return record.data
              ? record.data.siteNonObservable
                ? "Oui"
                : "Non"
              : "";
          }}
        />
        <TextField
          label="Description du site observé"
          source="data.description.descriptionSite"
        />
        <FunctionField
          label="Âge des roches"
          render={(record) => {
            return record.data
              ? record.data.age_roche.neSaisPas
                ? "Ne sais pas"
                : record.data.age_roche.age
                  .map((age) => age.title)
                  .sort()
                  .join(", ")
              : "";
          }}
        />
        <InteretPhenomeneField label="Intérêts et phénomènes" />
      </SimpleShowLayout>
    </Card>
  );
};

DescriptionCard.propTypes = {
  record: PropTypes.object,
  observatoryId: PropTypes.string,
};

const Diagnostic = ({ record, ...rest }) => {
  const diagnostic = getDiagnostic(record);

  if (!diagnostic) {
    return (
      <SimpleShowLayout>
        <Typography variant="body2">Aucun diagnostic</Typography>
      </SimpleShowLayout>
    );
  }

  return (
    <SimpleShowLayout record={diagnostic} {...rest}>
      <ReferenceField
        label="Auteur"
        source="userId"
        reference={
          "observatories/" + process.env.REACT_APP_VIGIETERRE_OBS_ID + "/users"
        }
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <DateField label="Date" source="data.dateObservation" />
      <TextField
        label="Commentaire synthétique"
        source="data.commentaireSynthetique"
      />
      <TextField label="Action envisagée" source="data.actionEnvisagee" />
    </SimpleShowLayout>
  );
};

Diagnostic.propTypes = {
  record: PropTypes.object,
};

export const ParticipationShow = (props) => {
  return (
    <Show title={<ParticipationNameField />} {...props}>
      <TabbedShowLayout>
        <Tab label="Signalement">
          <ReferenceField
            label="Observateur"
            source="userId"
            reference={
              "observatories/" +
              process.env.REACT_APP_VIGIETERRE_OBS_ID +
              "/users"
            }
            link="show"
          >
            <UserNameField />
          </ReferenceField>
          <FunctionField
            label="Département"
            render={(record) =>
              record.observationArea
                ? `${departements.get(record.observationArea.departement)} (${record.observationArea.departement
                })`
                : ""
            }
          />
          <DateField
            label="Date de la sortie"
            source="data.dateObservation"
            showTime
          />
          <TextField
            label="Indication d'accès"
            source="observationArea.data.indicationAcces"
          />
          <FunctionField
            label="Site accessible"
            render={(record) => {
              return record.data.localisation &&
                record.data.localisation.siteAccessibilite
                ? "Oui"
                : "Non";
            }}
          />
          <ReferenceField
            link={false}
            label="Nature du site"
            source="data.caracteristiques.natureSite"
            reference="ThesaurusValues"
          >
            <TextField source="title" />
          </ReferenceField>
          <TextField
            label="Commentaire sur la nature du site"
            source="data.caracteristiques.commentaire"
          />
          <ReferenceField
            link={false}
            label="Nature des roches du site"
            source="data.caracteristiques.natureRoche"
            reference="ThesaurusValues"
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            link={false}
            label="Taille de l'affleurement"
            source="data.caracteristiques.tailleAffleurement"
            reference="ThesaurusValues"
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            link={false}
            label="Précision"
            source="data.caracteristiques.natureSitePrecision"
            reference="ThesaurusValues"
          >
            <TextField source="title" />
          </ReferenceField>
          <ParticipationMediaField
            label="Vue d'ensemble"
            relation="imgVueEnsemble"
            size="medium"
          />
          <ParticipationMediaField
            label="Détail de l'affleurement"
            relation="imgDetailAffleurement"
            size="medium"
          />
          <DateField label="Créé le" source="created" showTime />
          <DateField label="Mis à jour le" source="updated" showTime />
          <Edit {...props} id={props.id}>
            <SimpleForm toolbar={<DefaultEditToolbar />}>
              <PublishedInput label="Participation publiée" />
            </SimpleForm>
          </Edit>
        </Tab>
        <Tab label="Descriptions">
          <Descriptions />
        </Tab>
        <Tab label="Diagnostic">
          <Diagnostic />
        </Tab>
        <Tab label="Commentaires">
          <ResourceCommentList
            resourceType="Participation"
            observatoryId={process.env.REACT_APP_VIGIETERRE_OBS_ID}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

ParticipationShow.propTypes = {
  id: PropTypes.string,
};
